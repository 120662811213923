/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
        // JavaScript to be fired on all pages

        /*         
                        
                   (function() {
                       var path = '//easy.myfonts.net/v2/js?sid=214455(font-family=Harmonia+Sans+Std+SemiBold)&sid=214457(font-family=Harmonia+Sans+Std+Bold)&sid=214453(font-family=Harmonia+Sans+Std+Regular)&sid=214451(font-family=Harmonia+Sans+Std+Light)&key=gkfUa0Pvs8',
                           protocol = ('https:' == document.location.protocol ? 'https:' : 'http:'),
                           trial = document.createElement('script');
                       trial.type = 'text/javascript';
                       trial.async = true;
                       trial.src = protocol + path;
                       var head = document.getElementsByTagName("head")[0];
                       head.appendChild(trial);
                   })();

                 */

        $(".wpcf7-text").jvFloat();

        $("ul.navbar-nav > li").each(function (i) {
          i = i + 1;
          $(this).addClass("_" + i);
        });

        $(".menu-btn").click(function () {
          $(".hamburger").toggleClass("is-active");
          $(".nav-overlay").fadeToggle(300, "linear");
          $(".start-btn").fadeToggle(300, "linear");
          $(".navbar-fixed-top").toggleClass("nav-on", "addOrRemove");
          $("footer").toggleClass("nav-on", "addOrRemove");
          $("ul.navbar-nav > li").toggleClass(
            "animated fadeInUp _",
            "addOrRemove"
          );
          $(".menu-btn").toggleClass("nav-on", "addOrRemove");
          $(".xmas").toggleClass("xmas-on", "addOrRemove");

          if ($(".menu-btn i").parent().is(".nav-on")) {
            $(".menu-btn i").removeClass("fa-navicon");
            $(".menu-btn i").addClass("fa-times");
          } else {
            $(".menu-btn i").removeClass("fa-times");
            $(".menu-btn i").addClass("fa-navicon");
          }

          $(".logo1").fadeToggle(10);
          $(".logo2").fadeToggle(10);
        });

        var controller = new ScrollMagic.Controller();

        if ($(window).width() >= 992) {
          var scener = new ScrollMagic.Scene({
            offset: "10%",
            triggerHook: "onEnter",
          })
            .setClassToggle(".navbar", "shrink")
            .setTween(
              TweenMax.fromTo(
                ".start-btn",
                0.5,
                {
                  marginRight: "60px",
                  opacity: "0",
                },
                {
                  marginRight: "35px",
                  opacity: "1",
                }
              )
            )
            .addTo(controller);
        }
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      },
    },

    // Home page
    home: {
      init: function () {
        if ($(window).width() <= 992) {
          $(function () {
            //Set up instafeed
            var userfeed = new Instafeed({
              target: "instafeed",
              get: "user",
              userId: 231950817,
              accessToken: "231950817.1677ed0.6aae430ed8fe42d1a591f4bf56e5b4bb",
              links: false,
              limit: 20,
              sortBy: "random",
              resolution: "standard_resolution",
              template:
                '<div class="instaitem" style="background:url({{image}});"></div>',
              after: function () {
                var images = $("#instafeed").find("div");
                if (images.length > 12) {
                  $(images.slice(12, images.length)).remove();
                }
              },
            });
            userfeed.run();
          });
        }

        if ($(window).width() >= 992) {
          $(function () {
            //Set up instafeed
            var feed = new Instafeed({
              target: "instafeed",
              get: "user",
              userId: 231950817,
              accessToken: "231950817.1677ed0.6aae430ed8fe42d1a591f4bf56e5b4bb",
              links: false,
              limit: 20,
              sortBy: "random",
              resolution: "standard_resolution",
              template:
                '<div class="instaitem" style="background:url({{image}});"></div>',
              after: function () {
                var images = $("#instafeed").find("div");
                if (images.length > 15) {
                  $(images.slice(15, images.length)).remove();
                }
              },
            });
            feed.run();
          });
        }

        $("a.mapbtn").mouseover(function () {
          $(".map").fadeToggle("slow", "linear");
        });

        $("a.mapbtn").mouseout(function () {
          $(".map").fadeToggle("slow", "linear");
        });

        var slider = $(".bxslider").bxSlider({
          auto: false,
          autoControls: false,
          controls: true,
          pager: false,
          speed: 2000,
          pause: 6000,
          randomStart: true,
          easing: "ease",
          mode: "horizontal",
        });

        var controller = new ScrollMagic.Controller();

        var tween3 = new TimelineMax().add([
          TweenMax.fromTo(
            ".home3 .box1",
            1,
            {
              top: -250,
            },
            {
              top: 20,
            }
          ),
          TweenMax.fromTo(
            ".home3 .box2",
            1,
            {
              top: 400,
            },
            {
              top: 50,
            }
          ),
          TweenMax.fromTo(
            ".home3 .box3",
            1,
            {
              top: 200,
            },
            {
              top: 0,
            }
          ),
          TweenMax.fromTo(
            ".home3 .box4",
            1,
            {
              top: 0,
            },
            {
              top: 200,
            }
          ),
          TweenMax.fromTo(
            ".home3 .box5",
            1,
            {
              top: 800,
            },
            {
              top: 400,
            }
          ),
        ]);

        var hidemap = new ScrollMagic.Scene({
          triggerElement: ".triggermenu",
          triggerHook: "onEnter",
        })
          .setClassToggle(".map", "hideme")
          .addTo(controller);

        if ($(window).width() >= 992) {
          var arrow1exit = new ScrollMagic.Scene({
            triggerElement: ".home2",
            triggerHook: "onEnter",
          })
            .setClassToggle("img.down-arrow", "fadeOutDown")
            .addTo(controller);
        }

        // build scene

        var startSlider = new ScrollMagic.Scene({
          triggerElement: ".home2",
          duration: "100%",
        })
          .on("enter", function () {
            slider.startAuto();
          })
          .on("leave", function () {
            slider.stopAuto();
          })

          .addTo(controller);

        var whitefooter = new ScrollMagic.Scene({
          triggerElement: ".whitefooter",
          triggerHook: "onEnter",
          duration: "135%",
          offset: 50,
        })

          .setClassToggle("footer", "white")
          .addTo(controller);

        var whitefooter2 = new ScrollMagic.Scene({
          triggerElement: ".whitefooter2",
          triggerHook: "onEnter",
        })

          .setClassToggle("footer", "white")
          .addTo(controller);

        var scene35 = new ScrollMagic.Scene({
          triggerElement: ".home3",
          triggerHook: "onEnter",
          duration: "200%",
        })
          .setTween(tween3)
          .addTo(controller);

        var scene = new ScrollMagic.Scene({
          triggerElement: ".home1",
          triggerHook: "onLeave",
        })

          .setPin(".home1")
          .addTo(controller);

        var scene2 = new ScrollMagic.Scene({
          triggerElement: ".home2",
          triggerHook: "onLeave",
        })

          .setPin(".home2")
          .addTo(controller);

        var scene3 = new ScrollMagic.Scene({
          triggerElement: ".home32",
          triggerHook: "onLeave",
        })

          .setPin(".home32")
          .addTo(controller);

        var scene4 = new ScrollMagic.Scene({
          triggerElement: ".home3",
          triggerHook: "onLeave",
        })

          .setPin(".home3")
          .addTo(controller);

        var scene5 = new ScrollMagic.Scene({
          triggerElement: ".home4",
          triggerHook: "onLeave",
        })
          .setPin(".home4")
          .addTo(controller);
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      },
    },
    // About us page, note the change from about-us to about_us.
    our_work: {
      init: function () {
        // JavaScript to be fired on the about us page
        $(".bxslider").bxSlider({
          auto: true,
          autoControls: false,
          controls: true,
          pager: false,
          mode: "fade",
        });
      },
    },
    // About us page, note the change from about-us to about_us.
    blog: {
      init: function () {
        // JavaScript to be fired on the about us page

        $(function () {
          $("article").matchHeight({
            byRow: true,
            property: "height",
            target: null,
            remove: false,
          });
        });

        $("select").selectric();
      },
    },

    // About us page, note the change from about-us to about_us.
    archive: {
      init: function () {
        // JavaScript to be fired on the about us page

        $(function () {
          $("article").matchHeight({
            byRow: true,
            property: "height",
            target: null,
            remove: false,
          });
        });

        $("select").selectric();
      },
    },

    // Post, note the change from about-us to about_us.
    single: {
      init: function () {
        // JavaScript to be fired on a post

        var controller = new ScrollMagic.Controller();
      },
    },

    // Post, note the change from about-us to about_us.
    contact: {
      init: function () {
        // JavaScript to be fired on a post

        var controller = new ScrollMagic.Controller();
      },
    },

    ecommerce: {
      init: function () {
        // JavaScript to be fired on the other us page
        var controller = new ScrollMagic.Controller();
        var ecom7 = new ScrollMagic.Scene({
          triggerElement: ".ecom7",
          triggerHook: "onCenter",
        })
          .setClassToggle(".bars", "fadeInUpLong")
          .addTo(controller);

        if ($(window).width() >= 992) {
          var cords = new TimelineMax().add([
            TweenMax.fromTo(
              ".one",
              0.5,
              {
                left: "-80%",
              },
              {
                left: "0",
              }
            ),
            TweenMax.fromTo(
              ".two",
              1,
              {
                left: "-80%",
              },
              {
                left: "0",
              }
            ),
            TweenMax.fromTo(
              ".three",
              1.5,
              {
                left: "-80%",
              },
              {
                left: "0",
              }
            ),
            TweenMax.fromTo(
              ".four",
              2,
              {
                left: "-80%",
              },
              {
                left: "0",
              }
            ),
            TweenMax.fromTo(
              ".five",
              2.5,
              {
                left: "-80%",
              },
              {
                left: "0",
              }
            ),
            TweenMax.fromTo(
              ".six",
              3,
              {
                left: "-80%",
              },
              {
                left: "0",
              }
            ),
          ]);

          var pics = new ScrollMagic.Scene({
            triggerElement: ".ecom7",
            triggerHook: "onCenter",
            duration: "100%",
          })
            .setTween(cords)
            .addTo(controller);
        }

        var tween5 = new TimelineMax().add([
          TweenMax.fromTo(
            ".mobilevid",
            1,
            {
              left: "-30%",
            },
            {
              left: "100%",
            }
          ),
          TweenMax.fromTo(
            ".ipad",
            1,
            {
              left: "-2000px",
            },
            {
              left: "80%",
            }
          ),
        ]);

        var pics2 = new ScrollMagic.Scene({
          triggerElement: ".ecom4",
          triggerHook: "onCenter",
        })
          .setTween(tween5)
          .addTo(controller);

        var Cont = {
            val: 0,
          },
          NewVal = 100;
        var countup = TweenLite.to(Cont, 2, {
          val: NewVal,
          roundProps: "val",
          onUpdate: function () {
            document.getElementById("counter").innerHTML = Cont.val;
          },
        });

        var tester2 = new ScrollMagic.Scene({
          triggerElement: ".ecom-examples",
          triggerHook: "onCenter",
          reverse: false,
        })
          .setTween(countup)
          .addTo(controller);

        var logos = new ScrollMagic.Scene({
          triggerElement: ".ecom-integrations",
          triggerHook: "onCenter",
          reverse: false,
        })
          .setClassToggle(".integrations", "zoomIn")
          .addTo(controller);
      },
    },

    web_development: {
      init: function () {
        var controller = new ScrollMagic.Controller();

        $(".bxslider").bxSlider({
          auto: true,
          autoControls: false,
          controls: false,
          pager: false,
          mode: "horizontal",
          slideWidth: 800,
          slideMargin: 100,
          preloadImages: "all",
          responsive: true,
          infiniteLoop: true,
          speed: 5000,

          pause: 500,
          randomStart: true,
        });

        if ($(window).width() >= 992) {
          new ScrollMagic.Scene({
            triggerElement: ".a",
            triggerHook: "onLeave",
            duration: "200%",
          })
            .setClassToggle(".bxslider", "hideme") // add class toggle
            .addTo(controller);

          var tween1 = new TimelineMax().add([
            TweenMax.fromTo(
              ".one",
              1,
              {
                marginTop: "0",
              },
              {
                marginTop: "20%",
              }
            ),
            TweenMax.fromTo(
              ".two",
              1,
              {
                marginTop: "30%",
              },
              {
                marginTop: "5%",
              }
            ),
          ]);

          var pics2 = new ScrollMagic.Scene({
            triggerElement: ".webdev3",
            triggerHook: "onEnter",
            duration: "200%",
          })
            .setTween(tween1)
            .addTo(controller);

          var tween2 = new TimelineMax().add([
            TweenMax.fromTo(
              ".three",
              1,
              {
                marginTop: "0",
              },
              {
                marginTop: "20%",
              }
            ),
            TweenMax.fromTo(
              ".four",
              1,
              {
                marginTop: "30%",
              },
              {
                marginTop: "5%",
              }
            ),
          ]);

          var pics3 = new ScrollMagic.Scene({
            triggerElement: ".b",
            triggerHook: "onEnter",
            duration: "200%",
          })
            .setTween(tween2)
            .addTo(controller);

          var tween3 = new TimelineMax().add([
            TweenMax.fromTo(
              ".five",
              1,
              {
                marginTop: "0",
              },
              {
                marginTop: "20%",
              }
            ),
            TweenMax.fromTo(
              ".six",
              1,
              {
                marginTop: "30%",
              },
              {
                marginTop: "5%",
              }
            ),
          ]);

          var pics4 = new ScrollMagic.Scene({
            triggerElement: ".c",
            triggerHook: "onEnter",
            duration: "200%",
          })
            .setTween(tween3)
            .addTo(controller);
        }

        var logos = new ScrollMagic.Scene({
          triggerElement: ".client-logos",
          triggerHook: "onCenter",
          reverse: false,
        })
          .setClassToggle(".client-logo", "fadeInUp")
          .addTo(controller);
      },
    },

    electronic_direct_mail_edm: {
      init: function () {
        var controller = new ScrollMagic.Controller();

        var pics2 = new ScrollMagic.Scene({
          triggerElement: ".edm7",
          triggerHook: "onCenter",
        })
          .setTween(
            TweenMax.fromTo(
              ".edm-graph",
              1,
              {
                left: "-100%",
              },
              {
                left: "100%",
              }
            )
          )
          .addTo(controller);
      },
    },

    multimedia_design: {
      init: function () {
        var controller = new ScrollMagic.Controller();

        var magazine = new ScrollMagic.Scene({
          triggerElement: ".design3",
          triggerHook: "onCenter",
        })
          .setTween(
            TweenMax.fromTo(
              ".magazine",
              1,
              {
                right: "-2000px",
              },
              {
                right: "0",
              }
            )
          )
          .addTo(controller);

        var tween1 = new TimelineMax().add([
          TweenMax.fromTo(
            ".one",
            1,
            {
              marginTop: "0",
            },
            {
              marginTop: "20%",
            }
          ),
          TweenMax.fromTo(
            ".two",
            1,
            {
              marginTop: "30%",
            },
            {
              marginTop: "5%",
            }
          ),
        ]);

        var pics2 = new ScrollMagic.Scene({
          triggerElement: ".design5",
          triggerHook: "onEnter",
          duration: "200%",
        })
          .setTween(tween1)
          .addTo(controller);

        var tween2 = new TimelineMax().add([
          TweenMax.fromTo(
            ".three",
            1,
            {
              marginTop: "0",
            },
            {
              marginTop: "10%",
            }
          ),
          TweenMax.fromTo(
            ".four",
            1,
            {
              marginTop: "30%",
            },
            {
              marginTop: "5%",
            }
          ),
        ]);

        var pics3 = new ScrollMagic.Scene({
          triggerElement: ".a",
          triggerHook: "onEnter",
          duration: "200%",
        })
          .setTween(tween2)
          .addTo(controller);
      },
    },

    post_type_archive_work: {
      init: function () {
        // JavaScript to be fired on the about us page

        $(".bxslider").bxSlider({
          auto: true,
          autoControls: false,
          controls: false,
          pager: false,
          mode: "fade",
        });

        var controller = new ScrollMagic.Controller();

        var scene = new ScrollMagic.Scene({
          triggerElement: ".portfolio1",
          triggerHook: "onLeave",
        })

          .setPin(".portfolio1", {
            pushFollowers: true,
          })
          .addTo(controller);

        $(".website-btn").click(function () {
          $(this).toggleClass("off", "addOrRemove");
          $(".website-item").toggleClass("off", "addOrRemove");
        });
        $(".graphic-btn").click(function () {
          $(this).toggleClass("off", "addOrRemove");
          $(".graphic-item").toggleClass("off", "addOrRemove");
        });
        $(".video-btn").click(function () {
          $(this).toggleClass("off", "addOrRemove");
          $(".video-item").toggleClass("off", "addOrRemove");
        });
      },
    },

    work_with_us: {
      init: function () {
        // JavaScript to be fired on the about us page
        var controller = new ScrollMagic.Controller();
        var tween4 = new TimelineMax().add([
          TweenMax.fromTo(
            ".team1",
            1,
            {
              top: "0%",
            },
            {
              top: "20%",
            }
          ),
          TweenMax.fromTo(
            ".team2",
            1,
            {
              top: "55%",
            },
            {
              top: "30%",
            }
          ),
          TweenMax.fromTo(
            ".team3",
            1,
            {
              top: "20%",
            },
            {
              top: "0%",
            }
          ),
          TweenMax.fromTo(
            ".team4",
            1,
            {
              top: "80%",
            },
            {
              top: "65%",
            }
          ),
        ]);

        var pics = new ScrollMagic.Scene({
          triggerElement: ".gen3",
          triggerHook: "onEnter",
          duration: "200%",
        })
          .setTween(tween4)
          .addTo(controller);
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(
        document.body.className.replace(/-/g, "_").split(/\s+/),
        function (i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, "finalize");
        }
      );

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
